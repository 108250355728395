/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
   
 
    // {
    //     id   : 'assets',
    //     title: 'Assets',
    //     type : 'basic',
    //     icon : 'heroicons_outline:collection',
    //     link : '/assets'
    // },
    // {
    //     id   : 'manage-qr',
    //     title: 'QR Codes',
    //     type : 'basic',
    //     icon : 'heroicons_outline:view-grid-add',
    //     link : '/manage-qr'
    // },
    {
        id   : 'retail-outlets',
        title: 'Gas Station',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:office-building',
        link : '/retail-outlets'
    },
    //  {
    //     id   : 'sales',
    //     title: 'Summary',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/sales'
    // },
    // {
    //     id   : 'Summary',
    //     title: 'Summary',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'summarize',
    //     link : '/summary'
    // },
    {
        id   : 'du-transactions',
        title: 'Transactions',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'double_arrow',
        link : '/du-transactions'
    },
    // {
    //     id   : 'foirreports',
    //     title: 'Sales',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'double_arrow',
    //     link : '/summary/sales'
    // },
    {
        id   : 'pricing',
        title: 'Pricing',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'price_check',
        link : '/pricing'
    },
    {
        id   : 'shifts',
        title: 'Shifts',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'access_time',
        link : '/shifts'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:users',
        link : '/users'
    },
    // {
    //     id   : 'audit-log',
    //     title: 'Shift Log',
    //     type : 'basic',
    //     icon : 'sync',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     link : '/audit-log'
    // },
    {
        id   : 'audit',
        title: 'Audit Log',
        type : 'basic',
        icon : 'sync',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link : '/audit-log/audit'
    },
    {
        id   : 'alert',
        title: 'Alerts',
        type : 'basic',
        icon : 'feather:alert-triangle',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        link : '/alerts'
    },
    // {
    //     id   : 'reports',
    //     title: 'Reports',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/reports'
    // },
    {
        id   : 'reports',
        title: 'Reports',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'feather:file',
        link : '/reports'
    },
    // {
    //     id   : 'energy',
    //     title: 'Energy Consumption',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/alerts/enegry'
    // },
    
    // {
    //     id   : 'romap',
    //     title: 'Station Map',
    //     type : 'basic',
    //     // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
    //     icon : 'feather:file',
    //     link : '/reports/romap'
    // },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:cog',
        link : '/settings'
    },
    {
        id: 'offline',
        title: 'Station - 123610 Tag List',
        type : 'basic',
        icon : 'heroicons_outline:folder-open',
        link : '/offline',
    },
    {
        id   : 'user-manual',
        title: 'User Manual',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_solid:book-open',
        link : '/user-manual'
    },
    {
        id   : 'version',
        title: 'Versions',
        type : 'basic',
        // classes:{title:'text-primary-900', icon:' fill-current text-primary-900'},
        icon : 'heroicons_outline:information-circle',
        link : '/versions/version'
    }
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation
/*export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];*/
